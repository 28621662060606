/* eslint-disable react/prop-types */
import * as React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import PageContent from "../components/common/PageContent"
import Link from "../components/common/Link"
import ButtonLink from "../components/common/ButtonLink"
// import ShowContent from "../components/common/ShowContent"

const Show = ({ data: { prismicShow } }) => {
  const { data } = prismicShow
  return (
    <Layout>
      <PageContent>
        <h1>
          {data.title.text} - {data.date}
        </h1>
        <p>
          <strong>{data.venue.text}: </strong>
          {data.venue_address.text}
        </p>
        <div dangerouslySetInnerHTML={{ __html: data.content.html }} />
        {data.link.url && (
          <ButtonLink
            label={data.link_label.text}
            to={data.link.url}
            target="_blank"
            size="s"
          />
        )}
        <p>
          <Link to={"/#shows"}>&larr; View all shows</Link>
        </p>
      </PageContent>
    </Layout>
  )
}

export default Show

export const pageQuery = graphql`
  query ShowBySlug($uid: String!) {
    prismicShow(uid: { eq: $uid }) {
      uid
      first_publication_date(formatString: "DD/MM/YYYY")
      last_publication_date(formatString: "DD/MM/YYYY")
      data {
        title {
          text
        }
        venue {
          text
        }
        venue_address {
          text
        }
        location {
          text
        }
        date(formatString: "DD/MM/YYYY")
        link_label {
          text
        }
        link {
          url
        }
        content {
          html
        }
      }
    }
  }
`
