import styled from "styled-components"

const PageContent = styled.div`
  max-width: ${({ theme }) => theme.space[18]};
  width: 100%;
  margin: ${({ theme }) => `${theme.space[3]} auto ${theme.space[7]}`};
  padding: 0 ${({ theme }) => theme.space[2]};

  iframe {
    max-width: 100%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ theme }) => `${theme.space[3]} auto ${theme.space[8]}`};
  }
`

export default {
  PageContent,
}
